import React from "react";
import Panel from "../../../common/components/panel";
import {
  RmKeyBasicMappingTypeFragment,
  RmMappingDetailsFragment,
  useRmMappingSetPrimarySourceMutation,
} from "../../../graphql/generated";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import { DescriptionList } from "../../../common/components/descriptionlist";
import { EditableSource } from "../mapping-source";

const RMTextBasicMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmKeyBasicMappingTypeFragment;
}> = ({ mapping, mappingType }) => {
  const [{ fetching: setPrimarySourceFetching }, setPrimarySourceMutation] =
    useRmMappingSetPrimarySourceMutation();

  return (
    <Panel>
      <Panel.Title>Key Mapping</Panel.Title>
      <Panel.Body>
        <DescriptionList>
          <DescriptionListContainer title="Source">
            <EditableSource
              source={mappingType.source}
              onSubmit={(_, sourceInput) =>
                setPrimarySourceMutation({
                  mappingId: mapping.id,
                  source: sourceInput,
                })
              }
              isSubmitting={setPrimarySourceFetching}
            />
          </DescriptionListContainer>
          <DescriptionListContainer title="Details" span={4}>
            <p>
              Nothing to do here, really. A basic key mapping takes the key from
              the source field and puts it into the destination field.
            </p>
          </DescriptionListContainer>
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default RMTextBasicMappingTypeControl;
