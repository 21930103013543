import React from "react";
import Panel from "../../../common/components/panel";
import {
  RmMappingDetailsFragment,
  RmDateBasicMappingTypeFragment,
  useRmMappingUpdateDateBasicMutation,
  useRmMappingSetPrimarySourceMutation,
} from "../../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../../common/context/notification";
import LoadingIcon from "../../../common/components/loadingicon";
import { format } from "date-fns";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import { EditableSource } from "../mapping-source";
import { DescriptionList } from "../../../common/components/descriptionlist";

const RMDateBasicMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmDateBasicMappingTypeFragment;
}> = ({ mapping, mappingType }) => {
  const { updateNotification } = React.useContext(NotificationContext);

  const [{ fetching }, mutation] = useRmMappingUpdateDateBasicMutation();
  const [{ fetching: setPrimarySourceFetching }, setPrimarySourceMutation] =
    useRmMappingSetPrimarySourceMutation();

  const [validAfterIsNull, setValidAfterIsNull] = React.useState(
    mappingType.validAfterDate === null
  );
  const [validAfterValue, setValidAfterValue] = React.useState(
    mappingType.validAfterDate ?? format(new Date(), "yyyy-MM-dd")
  );
  const [invalidBeforeIsNull, setInvalidBeforeIsNull] = React.useState(
    mappingType.invalidBeforeDate === null
  );
  const [invalidBeforeValue, setInvalidBeforeValue] = React.useState(
    mappingType.invalidBeforeDate ?? format(new Date(), "yyyy-MM-dd")
  );

  const update = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      validAfter: validAfterIsNull ? null : validAfterValue,
      invalidBefore: invalidBeforeIsNull ? null : invalidBeforeValue,
    });
    if (!error) {
      updateNotification({
        notification: `Mapping updated`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    fetching,
    mutation,
    mapping,
    validAfterIsNull,
    validAfterValue,
    invalidBeforeIsNull,
    invalidBeforeValue,
    updateNotification,
  ]);

  const cancel = React.useCallback(() => {
    setValidAfterIsNull(mappingType.validAfterDate === null);
    setValidAfterValue(
      mappingType.validAfterDate ?? format(new Date(), "yyyy-MM-dd")
    );
    setInvalidBeforeIsNull(mappingType.invalidBeforeDate === null);
    setInvalidBeforeValue(
      mappingType.invalidBeforeDate ?? format(new Date(), "yyyy-MM-dd")
    );
  }, [
    setValidAfterIsNull,
    setValidAfterValue,
    setInvalidBeforeIsNull,
    setInvalidBeforeValue,
    mappingType,
  ]);

  return (
    <Panel>
      <Panel.Title>Date Mapping</Panel.Title>
      <Panel.Body>
        <DescriptionList>
          <DescriptionListContainer title="Source">
            <EditableSource
              source={mappingType.source}
              onSubmit={(_, sourceInput) =>
                setPrimarySourceMutation({
                  mappingId: mapping.id,
                  source: sourceInput,
                })
              }
              isSubmitting={setPrimarySourceFetching}
            />
          </DescriptionListContainer>
          <DescriptionListContainer title="Details" span={4}>
            <div className="py-2">
              <p>
                Invalid before – values on or before this value are considered
                invalid and will be ignored.
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="invalid-before"
                    checked={invalidBeforeIsNull}
                    onClick={() => setInvalidBeforeIsNull(true)}
                  />{" "}
                  No value
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="invalid-before"
                    checked={!invalidBeforeIsNull}
                    onClick={() => setInvalidBeforeIsNull(false)}
                  />
                  Value:{" "}
                  <input
                    type="date"
                    value={invalidBeforeValue}
                    onInput={(evt) => {
                      setInvalidBeforeValue(evt.currentTarget.value);
                      setInvalidBeforeIsNull(false);
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="py-2">
              <p>
                Valid after – values on or after this value are considered valid
                and will be set on the resulting field
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="valid-after"
                    checked={validAfterIsNull}
                    onClick={() => setValidAfterIsNull(true)}
                  />{" "}
                  No value
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="valid-after"
                    checked={!validAfterIsNull}
                    onClick={() => setValidAfterIsNull(false)}
                  />
                  Value:{" "}
                  <input
                    type="date"
                    value={validAfterValue}
                    onInput={(evt) => {
                      setValidAfterValue(evt.currentTarget.value);
                      setValidAfterIsNull(false);
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="py-2">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                disabled={fetching}
                onClick={update}
              >
                {fetching && <LoadingIcon />}
                Update
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                disabled={fetching}
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          </DescriptionListContainer>
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default RMDateBasicMappingTypeControl;
