import React from "react";
import InfoPopover from "./infopopover";

const DescriptionListContainer: React.FC<{
  title: string;
  span?: number;
  children?: React.ReactNode;
  info?: string;
}> = ({ title, span, children, info }) => {
  let spanClass = "sm:col-span-1";

  // Why not `sm:col-span-${span}`? Because hardcoding them makes some magic
  // parser in Tailwind actually pick them up and generate the right CSS.
  if (span === 1) {
    spanClass = "sm:col-span-1";
  }
  if (span === 2) {
    spanClass = "sm:col-span-2";
  }
  if (span === 3) {
    spanClass = "sm:col-span-3";
  }
  if (span === 4) {
    spanClass = "sm:col-span-4";
  }
  if (span === 5) {
    spanClass = "sm:col-span-5";
  }
  if (span === 6) {
    spanClass = "sm:col-span-6";
  }
  if (span === 7) {
    spanClass = "sm:col-span-7";
  }
  if (span === 8) {
    spanClass = "sm:col-span-8";
  }

  return (
    <div className={spanClass}>
      <dt className="text-sm font-medium text-gray-500">
        {title} {!!info && <InfoPopover content={info} />}
      </dt>
      <dd className="mt-1 text-sm text-gray-900">{children}</dd>
    </div>
  );
};

export default DescriptionListContainer;
