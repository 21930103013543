import React from "react";
import Panel from "../../../common/components/panel";
import {
  RmMappingDetailsFragment,
  RmNumberBasicMappingTypeFragment,
  useRmMappingSetPrimarySourceMutation,
  useRmMappingUpdateNumberBasicMutation,
} from "../../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../../common/context/notification";
import LoadingIcon from "../../../common/components/loadingicon";
import CommonSwitch from "../../../common/components/switch";
import { DescriptionList } from "../../../common/components/descriptionlist";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import { EditableSource } from "../mapping-source";

const RMNumberBasicMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmNumberBasicMappingTypeFragment;
}> = ({ mapping, mappingType }) => {
  const { updateNotification } = React.useContext(NotificationContext);

  const [{ fetching }, mutation] = useRmMappingUpdateNumberBasicMutation();
  const [{ fetching: setPrimarySourceFetching }, setPrimarySourceMutation] =
    useRmMappingSetPrimarySourceMutation();

  const [validAboveIsNull, setValidAboveIsNull] = React.useState(
    mappingType.validAbove === null
  );
  const [validAboveValue, setValidAboveValue] = React.useState(
    mappingType.validAbove ?? 0.0
  );
  const [invalidBelowIsNull, setInvalidBelowIsNull] = React.useState(
    mappingType.invalidBelow === null
  );
  const [invalidBelowValue, setInvalidBelowValue] = React.useState(
    mappingType.invalidBelow ?? 0.0
  );
  const [suppressParseError, setSuppressParseError] = React.useState(
    mappingType.suppressParseError
  );

  const update = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      validAbove: validAboveIsNull ? null : validAboveValue,
      invalidBelow: invalidBelowIsNull ? null : invalidBelowValue,
      suppressParseError: suppressParseError,
    });
    if (!error) {
      updateNotification({
        notification: `Mapping updated`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    fetching,
    mutation,
    mapping,
    validAboveIsNull,
    validAboveValue,
    invalidBelowIsNull,
    invalidBelowValue,
    suppressParseError,
    updateNotification,
  ]);

  const cancel = React.useCallback(() => {
    setValidAboveIsNull(mappingType.validAbove === null);
    setValidAboveValue(mappingType.validAbove ?? 0.0);
    setInvalidBelowIsNull(mappingType.invalidBelow === null);
    setInvalidBelowValue(mappingType.invalidBelow ?? 0.0);
    setSuppressParseError(mappingType.suppressParseError);
  }, [
    setValidAboveIsNull,
    setValidAboveValue,
    setInvalidBelowIsNull,
    setInvalidBelowValue,
    setSuppressParseError,
    mappingType,
  ]);

  return (
    <Panel>
      <Panel.Title>Number Mapping</Panel.Title>
      <Panel.Body>
        <DescriptionList>
          <DescriptionListContainer title="Source">
            <EditableSource
              source={mappingType.source}
              onSubmit={(_, sourceInput) =>
                setPrimarySourceMutation({
                  mappingId: mapping.id,
                  source: sourceInput,
                })
              }
              isSubmitting={setPrimarySourceFetching}
            />
          </DescriptionListContainer>
          <DescriptionListContainer title="Details" span={4}>
            <div>
              <p>
                Invalid below – values below this value are considered invalid
                and will be ignored.
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="invalid-below"
                    checked={invalidBelowIsNull}
                    onClick={() => setInvalidBelowIsNull(true)}
                  />{" "}
                  No value
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="invalid-below"
                    checked={!invalidBelowIsNull}
                    onClick={() => setInvalidBelowIsNull(false)}
                  />
                  Value:{" "}
                  <input
                    type="number"
                    value={invalidBelowValue}
                    onInput={(evt) => {
                      setInvalidBelowValue(evt.currentTarget.valueAsNumber);
                      setInvalidBelowIsNull(false);
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="py-2">
              <p>
                Valid above – values above this value are considered valid and
                will be set on the resulting field
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="valid-above"
                    checked={validAboveIsNull}
                    onClick={() => setValidAboveIsNull(true)}
                  />{" "}
                  No value
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="valid-above"
                    checked={!validAboveIsNull}
                    onClick={() => setValidAboveIsNull(false)}
                  />
                  Value:{" "}
                  <input
                    type="number"
                    value={validAboveValue}
                    onInput={(evt) => {
                      setValidAboveValue(evt.currentTarget.valueAsNumber);
                      setValidAboveIsNull(false);
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="py-2">
              <p>
                Report number parsing problems – whether or not failing to parse
                the value as a number will cause a problem to be created. This
                is more targeted than the general "Report problems" setting and
                should be used for MLS number fields that are known to
                occassionally not contain numbers.
              </p>
              <div>
                <CommonSwitch
                  label="Report number parse problems"
                  enabled={!suppressParseError}
                  toggle={() => setSuppressParseError(!suppressParseError)}
                />
              </div>
            </div>
            <div className="py-2">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                disabled={fetching}
                onClick={update}
              >
                {fetching && <LoadingIcon />}
                Update
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                disabled={fetching}
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          </DescriptionListContainer>
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default RMNumberBasicMappingTypeControl;
