import React from "react";
import Panel from "../../../common/components/panel";
import {
  ResourceMetadataFieldFragment,
  RmBooleanLookupMappingTypeFragment,
  RmMappingDetailsFragment,
  RmProblemConnectionFragment,
  useResourceMetadataForFieldQuery,
  useRmMappingSetBooleanLookupTransformMutation,
  useRmMappingSetPrimarySourceMutation,
  useRmMappingUpdateBooleanLookupMutation,
  useRmProblemAcknowledgeMutation,
} from "../../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../../common/context/notification";
import LoadingIcon from "../../../common/components/loadingicon";
import ProblemStatus from "../problem-status";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import CommonSwitch from "../../../common/components/switch";
import { ResourceMetadataLookupValueComponent } from "../resource-metadata/resource-metadata-lookup-value-component";
import { ResourceMetadataFieldComponent } from "../resource-metadata/resource-metadata-field-component";
import { EditableSource, sourceName } from "../mapping-source";
import { DescriptionList } from "../../../common/components/descriptionlist";

const RMBooleanMappingMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmBooleanLookupMappingTypeFragment;
  problems?: RmProblemConnectionFragment;
}> = ({ mapping, mappingType, problems }) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const [source, setSource] = React.useState<string>("");
  const [destination, setDestination] = React.useState<boolean | null>(null);

  const [{ fetching }, mutation] =
    useRmMappingSetBooleanLookupTransformMutation();
  const [{ fetching: updateFetching }, updateBooleanLookupMutation] =
    useRmMappingUpdateBooleanLookupMutation();
  const [{ fetching: setPrimarySourceFetching }, setPrimarySourceMutation] =
    useRmMappingSetPrimarySourceMutation();

  const [{ data: resourceMetadata }] = useResourceMetadataForFieldQuery({
    variables: {
      id: mapping.field.rootResource.resourceMetadataId,
      fieldName:
        mappingType.source.__typename === "RMFieldMappingSource"
          ? mappingType.source.name
          : "",
    },
    pause: mappingType.source.__typename !== "RMFieldMappingSource",
  });

  const resourceMetadataField =
    resourceMetadata?.resourceMetadata.field ?? undefined;

  const setLookup = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      source,
      destination,
    });
    if (!error) {
      updateNotification({
        notification: `Mapping updated`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [fetching, mutation, mapping, source, destination, updateNotification]);

  const onSplitOnCommaToggleChanged = React.useCallback(async () => {
    if (updateFetching) {
      return;
    }
    const { error } = await updateBooleanLookupMutation({
      mappingId: mapping.id,
      splitOnComma: !mappingType.splitOnComma,
    });
    if (!error) {
      updateNotification({
        notification: `Split on commas toggled`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    mapping.id,
    mappingType.splitOnComma,
    updateFetching,
    updateBooleanLookupMutation,
    updateNotification,
  ]);

  return (
    <>
      <Panel>
        <Panel.Title>Boolean Lookup Mapping</Panel.Title>
        <Panel.Body>
          {/* <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3"> */}
          {/* <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4"></dl> */}
          <DescriptionList>
            <DescriptionListContainer title="Source">
              <EditableSource
                source={mappingType.source}
                onSubmit={(_, sourceInput) =>
                  setPrimarySourceMutation({
                    mappingId: mapping.id,
                    source: sourceInput,
                  })
                }
                isSubmitting={setPrimarySourceFetching}
              />
            </DescriptionListContainer>
            <DescriptionListContainer
              title="Split on commas"
              info="Whether or not to treat a comma-separated string as multiple values. Enable this for RETS MLSes and certain API MLSes that return multiple values in a single comma-separated string."
            >
              <CommonSwitch
                label="Split on commas"
                enabled={mappingType.splitOnComma ?? false}
                toggle={onSplitOnCommaToggleChanged}
              />
            </DescriptionListContainer>
            <DescriptionListContainer title="Transforms" span={4}>
              <table className="w-full text-sm text-left">
                <thead>
                  <tr>
                    <th className="p-1">
                      Source:{" "}
                      <ResourceMetadataFieldComponent
                        source={mappingType.source}
                        field={resourceMetadataField}
                      />
                    </th>
                    <th className="p-1">Destination: {mapping.field.name}</th>
                  </tr>
                </thead>
                <tbody>
                  {mappingType.booleanTransforms.map((transform, idx) => {
                    return (
                      <tr key={idx} className="border-t">
                        <td className="p-1">
                          <ResourceMetadataLookupValueComponent
                            field={resourceMetadataField}
                            value={transform.from}
                          />
                        </td>
                        <td className="p-1">
                          {transform.to === true && <>true</>}
                          {transform.to === false && <>false</>}
                          {transform.to === undefined && (
                            <span className="text-gray-400">ignore</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr key="add" className="border-t">
                    <td className="p-1">
                      <input
                        type="text"
                        value={source}
                        onChange={(e) => setSource(e.target.value)}
                      />
                    </td>
                    <td className="p-1">
                      <BooleanSelector
                        value={destination}
                        setValue={setDestination}
                      />{" "}
                      {!fetching && (
                        <button onClick={() => setLookup()}>Set</button>
                      )}
                      {fetching && <LoadingIcon />}
                    </td>
                  </tr>
                </tbody>
              </table>
            </DescriptionListContainer>
          </DescriptionList>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>Suggestions</Panel.Title>
        <Panel.Body>
          <table className="w-full text-sm text-left">
            <thead>
              <tr>
                <th className="p-1">
                  Source: {sourceName(mappingType.source)}
                </th>
                <th className="p-1">Destination: {mapping.field.name}</th>
                <th className="p-1"></th>
              </tr>
            </thead>
            <tbody>
              {problems?.edges.map((edge) => (
                <Suggestion
                  key={edge.node.id}
                  edge={edge}
                  mapping={mapping}
                  resourceMetadataField={resourceMetadataField}
                />
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
};

const Suggestion: React.FC<{
  edge: RmProblemConnectionFragment["edges"][number];
  mapping: RmMappingDetailsFragment;
  resourceMetadataField?: ResourceMetadataFieldFragment;
}> = ({ edge, mapping, resourceMetadataField }) => {
  const { updateNotification } = React.useContext(NotificationContext);

  if (edge.node.data["problem_type"] !== "unidentified_lookup") {
    return <></>;
  }

  const problem = edge.node;
  const source = problem.data["value"];
  const isAcknowledged = problem.acknowledgedAt != null;

  const [destination, setDestination] = React.useState<boolean | null>(null);

  const [{ fetching }, mutation] =
    useRmMappingSetBooleanLookupTransformMutation();
  const [, mutationAcknowledge] = useRmProblemAcknowledgeMutation();

  const setLookup = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      source,
      destination,
    });
    if (!error) {
      updateNotification({
        notification: `Mapping updated`,
        notificationType: NotificationType.Success,
      });

      mutationAcknowledge({
        problemId: problem.id,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    fetching,
    mutation,
    mutationAcknowledge,
    mapping,
    problem,
    source,
    destination,
    updateNotification,
  ]);

  return (
    <tr>
      <td>
        <ResourceMetadataLookupValueComponent
          field={resourceMetadataField}
          value={source}
        />
      </td>
      <td>
        <BooleanSelector
          value={destination}
          setValue={setDestination}
          disabled={isAcknowledged}
        />
        {!fetching && (
          <button onClick={() => setLookup()} disabled={isAcknowledged}>
            Set
          </button>
        )}
        {fetching && <LoadingIcon />}
      </td>

      <td>
        <ProblemStatus problem={problem} />
      </td>
    </tr>
  );
};

const BooleanSelector: React.FC<{
  value: boolean | null;
  disabled?: boolean;
  setValue: (newValue: boolean | null) => void;
}> = ({ value, disabled, setValue }) => {
  const innerValue =
    value === null
      ? "special:null"
      : value === true
      ? "special:true"
      : "special:false";

  const onChange = React.useCallback(
    (newValue: string) => {
      if (newValue === "special:null") {
        setValue(null);
      } else if (newValue === "special:true") {
        setValue(true);
      } else {
        setValue(false);
      }
    },
    [setValue]
  );

  return (
    <select
      value={innerValue}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    >
      <option value="special:null" key="special:null">
        ignore
      </option>
      <option value="special:true" key="special:true">
        true
      </option>
      <option value="special:false" key="special:false">
        false
      </option>
    </select>
  );
};

export default RMBooleanMappingMappingTypeControl;
