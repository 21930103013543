import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import { UpdateLenderDialog } from "./dialogs";
import { NotificationContext, NotificationType } from "../../common/context/notification";
import { LenderSet, LenderFragment, UpdateLenderDetails, useUpdateLenderMutation } from "../../graphql/generated";

const LenderItemActions: React.FC<{lender?: LenderFragment;}> = ({ lender }) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const [updateLenderOpen, setUpdateLenderOpen] = React.useState(false);
  const [, updateLender] = useUpdateLenderMutation();
  const [initialLenderSet, setInitialLenderSet] = React.useState("");
  const updateLenderCallback = React.useCallback(
    async (lenderDetails: UpdateLenderDetails) => {
      setInitialLenderSet(lenderDetails.lenderSet?.toString() || "");

      const { error } = await updateLender({
        id: lender?.id || "",
        details: {
          lenderSet: lenderDetails.lenderSet as LenderSet,
        },
      });
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Lender has been updated",
          notificationType: NotificationType.Success,
        });
      }
      setUpdateLenderOpen(false);
    },
    [
      lender?.id,
      setUpdateLenderOpen,
      updateNotification,
      setInitialLenderSet,
      updateLender,
    ]
  );
  return (
    <>
    <Menu
      as="div"
      className="relative inline-block text-left mt-4 sm:mt-0 z-50"
    >
      <div>
        <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
          <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setUpdateLenderOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <ArrowRightOnRectangleIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Update Lender
                  </div>
                )}
              </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    <UpdateLenderDialog
    isOpen={updateLenderOpen}
    onClose={() => setUpdateLenderOpen(false)}
    onSubmit={updateLenderCallback}
    initialLenderSet={initialLenderSet as LenderSet}
    />
  </>);
};

export default LenderItemActions;
