import React from "react";
import { classNames } from "../../common/utils/classnames";
import { RmFieldType, RmLookupMode, RmSource } from "../../graphql/generated";

type PillForSourceType = React.FC<{ source?: RmSource }>;
const PillForSource: PillForSourceType = ({ source }) => {
  if (source === undefined) {
    return <></>;
  }
  switch (source) {
    case RmSource.ResoDataDictionary:
      return <Pill bgColor="bg-green-500">Reso DD</Pill>;
    case RmSource.Zenlist:
      return <Pill bgColor="bg-blue-500">Zenlist</Pill>;
    case RmSource.Internal:
      return <Pill bgColor="bg-orange-500">Internal</Pill>;
    case RmSource.Local:
      return <Pill bgColor="bg-purple-500">Local</Pill>;
  }
};

type PillForFieldTypeType = React.FC<{
  fieldType?: RmFieldType;
  reziLookupMode?: RmLookupMode;
  localLookupMode?: RmLookupMode;
}>;
const PillForFieldType: PillForFieldTypeType = ({
  fieldType,
  reziLookupMode,
  localLookupMode,
}) => {
  if (fieldType === undefined) {
    return <></>;
  }

  function lookupModeChar(lookupMode?: RmLookupMode): string {
    if (lookupMode === undefined) {
      return "?";
    }
    switch (lookupMode) {
      case RmLookupMode.None:
        return "−";
      case RmLookupMode.Single:
        return "1";
      case RmLookupMode.Many:
        return "+";
    }
  }

  const lookupMode = `R${lookupModeChar(reziLookupMode)} L${lookupModeChar(
    localLookupMode
  )}`;

  switch (fieldType) {
    case RmFieldType.Boolean:
      return <Pill bgColor="bg-gray-400">Boolean</Pill>;
    case RmFieldType.Date:
      return <Pill bgColor="bg-gray-400">Date</Pill>;
    case RmFieldType.Lookup:
      return <Pill bgColor="bg-gray-400">Lookup {lookupMode}</Pill>;
    case RmFieldType.Number:
      return <Pill bgColor="bg-gray-400">Number</Pill>;
    case RmFieldType.Resource:
      return <Pill bgColor="bg-gray-400">Resource</Pill>;
    case RmFieldType.ResourceList:
      return <Pill bgColor="bg-gray-400">Resource List</Pill>;
    case RmFieldType.Text:
      return <Pill bgColor="bg-gray-400">Text</Pill>;
    case RmFieldType.Key:
      return <Pill bgColor="bg-gray-400">Key</Pill>;
    case RmFieldType.Timestamp:
      return <Pill bgColor="bg-gray-400">Timestamp</Pill>;
  }
};

const Pill: React.FC<{ bgColor?: string } & React.PropsWithChildren> & {
  forSource: PillForSourceType;
  forFieldType: PillForFieldTypeType;
} = ({ bgColor, children }) => {
  return (
    <span
      className={classNames(
        bgColor ?? "bg-gray-500",
        "text-white text-xs px-1 py-0.5 mx-0.5 rounded-md whitespace-nowrap"
      )}
    >
      {children}
    </span>
  );
};

Pill.forSource = PillForSource;
Pill.forFieldType = PillForFieldType;

export default Pill;
