import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Timestamp from "../../common/components/timestamp";
import {
  LenderQuery,
  useLenderAgentCollaborationsByLenderIdQuery,
} from "../../graphql/generated";
import Panel from "../../common/components/panel";
import LenderAgentCollaborations from "./lenderagentcollaborations";
import LenderItemActions from "./itemactions";

const LenderSummary: React.FC<{ lender?: LenderQuery["lender"] }> = ({ lender }) => {
  const [
    { data: lenderAgentCollaborationsData },
  ] = useLenderAgentCollaborationsByLenderIdQuery({
    variables: { lenderId: lender?.id ?? "" },
  });
  
  return (
    <>
      <Panel>
        <Panel.Title>
          <div className="sm:flex sm:items-center sm:justify-between">
            Zenlist Data
            <div className="mt-5 flex sm:mt-0 sm:mr-4"><LenderItemActions lender={lender}/></div>
          </div>
        </Panel.Title>
        <Panel.Body summary>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            <DescriptionListItem
              title="Company"
              info="The company that this lender belongs to."
              value={lender?.companyName}
            />
            <DescriptionListItem
              title="Lender Set"
              info="The set of lenders this lender belongs to."
              value={lender?.lenderSet}
            />
            <DescriptionListItem
              title="Account"
              value={lender?.account?.name}
              link={`/accounts/${lender?.account?.id}`}
              info="The account on Zenlist that has claimed this lender."
            />

            <DescriptionListContainer
              title="Last Modified"
              info="The last time this lender was modified in the Zenlist lender."
            >
              <Timestamp timestamp={lender?.modifiedAt} format="short" popover />
            </DescriptionListContainer>
          </dl>
        </Panel.Body>
      </Panel>
      <LenderAgentCollaborations lenderAgentCollaborations={lenderAgentCollaborationsData?.lenderAgentCollaborationsByLenderId}></LenderAgentCollaborations>
    </>
  );
};

export default LenderSummary;
