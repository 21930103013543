import React from "react";
import Panel from "../../../common/components/panel";
import {
  RmMappingDetailsFragment,
  RmTimestampBasicMappingTypeFragment,
  useRmMappingSetPrimarySourceMutation,
  useRmMappingUpdateTimestampBasicMutation,
} from "../../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../../common/context/notification";
import LoadingIcon from "../../../common/components/loadingicon";
import { parseISO } from "date-fns";
import { DescriptionList } from "../../../common/components/descriptionlist";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import { EditableSource } from "../mapping-source";

function formatForDateTimeLocalInput(s: string): string {
  // Why isn't there a way for date-fns to format a date into UTC?
  function twoDigit(val: number): string {
    if (val < 10) {
      return `0${val}`;
    } else {
      return `${val}`;
    }
  }

  const dt = parseISO(s);

  return `${dt.getUTCFullYear()}-${twoDigit(dt.getUTCMonth() + 1)}-${twoDigit(
    dt.getUTCDate()
  )}T${twoDigit(dt.getUTCHours())}:${twoDigit(dt.getUTCMinutes())}`;
}

const RMTimestampBasicMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmTimestampBasicMappingTypeFragment;
}> = ({ mapping, mappingType }) => {
  const { updateNotification } = React.useContext(NotificationContext);

  const [{ fetching }, mutation] = useRmMappingUpdateTimestampBasicMutation();
  const [{ fetching: setPrimarySourceFetching }, setPrimarySourceMutation] =
    useRmMappingSetPrimarySourceMutation();

  const [validAfterIsNull, setValidAfterIsNull] = React.useState(
    mappingType.validAfterTimestamp === null
  );
  const [validAfterValue, setValidAfterValue] = React.useState(
    mappingType.validAfterTimestamp
      ? formatForDateTimeLocalInput(mappingType.validAfterTimestamp)
      : formatForDateTimeLocalInput(new Date().toISOString())
  );
  const [invalidBeforeIsNull, setInvalidBeforeIsNull] = React.useState(
    mappingType.invalidBeforeTimestamp === null
  );
  const [invalidBeforeValue, setInvalidBeforeValue] = React.useState(
    mappingType.invalidBeforeTimestamp
      ? formatForDateTimeLocalInput(mappingType.invalidBeforeTimestamp)
      : formatForDateTimeLocalInput(new Date().toISOString())
  );

  const update = React.useCallback(async () => {
    if (fetching) {
      return;
    }

    const validAfterValueConverted = parseISO(
      `${validAfterValue}Z`
    ).toISOString();
    const invalidBeforeValueConverted = parseISO(
      `${invalidBeforeValue}Z`
    ).toISOString();

    const { error } = await mutation({
      mappingId: mapping.id,
      validAfter: validAfterIsNull ? null : validAfterValueConverted,
      invalidBefore: invalidBeforeIsNull ? null : invalidBeforeValueConverted,
    });
    if (!error) {
      updateNotification({
        notification: `Mapping updated`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    fetching,
    mutation,
    mapping,
    validAfterIsNull,
    validAfterValue,
    invalidBeforeIsNull,
    invalidBeforeValue,
    updateNotification,
  ]);

  const cancel = React.useCallback(() => {
    setValidAfterIsNull(mappingType.validAfterTimestamp === null);
    setValidAfterValue(
      mappingType.validAfterTimestamp ?? new Date().toISOString()
    );
    setInvalidBeforeIsNull(mappingType.invalidBeforeTimestamp === null);
    setInvalidBeforeValue(
      mappingType.invalidBeforeTimestamp ?? new Date().toISOString()
    );
  }, [
    setValidAfterIsNull,
    setValidAfterValue,
    setInvalidBeforeIsNull,
    setInvalidBeforeValue,
    mappingType,
  ]);

  return (
    <Panel>
      <Panel.Title>Date Mapping</Panel.Title>
      <Panel.Body>
        <DescriptionList>
          <DescriptionListContainer title="Source">
            <EditableSource
              source={mappingType.source}
              onSubmit={(_, sourceInput) =>
                setPrimarySourceMutation({
                  mappingId: mapping.id,
                  source: sourceInput,
                })
              }
              isSubmitting={setPrimarySourceFetching}
            />
          </DescriptionListContainer>
          <DescriptionListContainer title="Details" span={4}>
            <div>
              <p>
                Invalid before – values on or before this value are considered
                invalid and will be ignored.
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="invalid-before"
                    checked={invalidBeforeIsNull}
                    onChange={() => setInvalidBeforeIsNull(true)}
                  />{" "}
                  No value
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="invalid-before"
                    checked={!invalidBeforeIsNull}
                    onChange={() => setInvalidBeforeIsNull(false)}
                  />
                  Value:{" "}
                  <input
                    type="datetime-local"
                    value={invalidBeforeValue}
                    onInput={(evt) => {
                      setInvalidBeforeValue(evt.currentTarget.value);
                      setInvalidBeforeIsNull(false);
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="py-2">
              <p>
                Valid after – values on or after this value are considered valid
                and will be set on the resulting field
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="valid-after"
                    checked={validAfterIsNull}
                    onChange={() => setValidAfterIsNull(true)}
                  />{" "}
                  No value
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    name="valid-after"
                    checked={!validAfterIsNull}
                    onChange={() => setValidAfterIsNull(false)}
                  />
                  Value:{" "}
                  <input
                    type="datetime-local"
                    value={validAfterValue}
                    onInput={(evt) => {
                      setValidAfterValue(evt.currentTarget.value);
                      setValidAfterIsNull(false);
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="py-2">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                disabled={fetching}
                onClick={update}
              >
                {fetching && <LoadingIcon />}
                Update
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                disabled={fetching}
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          </DescriptionListContainer>
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default RMTimestampBasicMappingTypeControl;
