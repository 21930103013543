import React from "react";
import { visualSpaces } from "./visual-spaces";
import {
  RmMappingSourceFragment,
  RmMappingSourceInput,
} from "../../graphql/generated";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { InboxArrowDownIcon } from "@heroicons/react/24/outline";
import RuleExpressionEditor from "./rule-expression-editor";

export function sourceName(
  source: RmMappingSourceFragment | undefined
): string | undefined {
  if (!source) {
    return;
  }
  switch (source.__typename) {
    case "RMFieldMappingSource":
      return visualSpaces(source.name);
    case "RMJsonPointerMappingSource":
      return visualSpaces(source.pointer);
    case "RMRuleMappingSource":
      return "<expression>";
  }
}

export function mappingSourceToInput(
  source: RmMappingSourceFragment
): RmMappingSourceInput {
  switch (source.__typename) {
    case "RMFieldMappingSource":
      return {
        field: {
          name: source.name,
        },
      };
    case "RMJsonPointerMappingSource":
      return {
        jsonPointer: {
          pointer: source.pointer,
        },
      };
    case "RMRuleMappingSource":
      return {
        rule: {
          expression: source.expression,
        },
      };
  }
}

export const EditableSource: React.FC<{
  source?: RmMappingSourceFragment;
  onSubmit: (
    source: RmMappingSourceFragment,
    sourceInput: RmMappingSourceInput
  ) => void;
  isSubmitting?: boolean;
}> = ({ source, onSubmit, isSubmitting }) => {
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);

  let label = "none";
  let description: string | undefined = "—";
  if (source) {
    switch (source?.__typename) {
      case "RMFieldMappingSource":
        label = "Field";
        description = visualSpaces(source.name);
        break;
      case "RMJsonPointerMappingSource":
        label = "JSON Pointer";
        description = visualSpaces(source.pointer);
        break;
      case "RMRuleMappingSource":
        label = "Rule";
        description = source.expression;
        break;
    }
  }

  return (
    <>
      <button
        className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 grid grid-flow-col rounded-lg border border-gray-300 bg-white shadow-sm hover:border-gray-400"
        onClick={() => setDialogIsOpen(true)}
      >
        <div className="text-sm font-medium text-gray-900 border-r border-gray-300 p-2">
          {label}
        </div>
        <div className="text-sm font-medium text-gray-900 p-2">
          {description}
        </div>
      </button>
      <SelectSourceDialog
        show={dialogIsOpen || isSubmitting || false}
        onCancel={() => setDialogIsOpen(false)}
        onSubmit={(source, sourceInput) => {
          onSubmit(source, sourceInput);
          setDialogIsOpen(false);
        }}
        source={source}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export const SelectSourceDialog: React.FC<{
  source?: RmMappingSourceFragment;
  onCancel: () => void;
  onSubmit: (
    source: RmMappingSourceFragment,
    sourceInput: RmMappingSourceInput
  ) => void;
  show?: boolean;
  submit?: React.ReactFragment;
  isSubmitting?: boolean;
}> = ({ source, onCancel, onSubmit, show, submit, isSubmitting }) => {
  const [fieldName, setFieldName] = React.useState(
    source?.__typename === "RMFieldMappingSource" ? source.name : ""
  );
  const [jsonPointer, setJsonPointer] = React.useState(
    source?.__typename === "RMJsonPointerMappingSource" ? source.pointer : ""
  );
  const [expression, setExpression] = React.useState(
    source?.__typename === "RMRuleMappingSource" ? source.expression : ""
  );
  const [selectedSource, setSelectedSource] = React.useState<
    | "RMFieldMappingSource"
    | "RMJsonPointerMappingSource"
    | "RMRuleMappingSource"
    | undefined
  >(source?.__typename);

  const isValid = !selectedSource;

  const handleSubmit = React.useCallback(() => {
    let newSource: RmMappingSourceFragment;

    if (!selectedSource) {
      return;
    }

    switch (selectedSource) {
      case "RMFieldMappingSource":
        newSource = {
          __typename: "RMFieldMappingSource",
          name: fieldName,
        };
        break;
      case "RMJsonPointerMappingSource":
        newSource = {
          __typename: "RMJsonPointerMappingSource",
          pointer: jsonPointer,
        };
        break;
      case "RMRuleMappingSource":
        newSource = {
          __typename: "RMRuleMappingSource",
          expression: expression,
        };
        break;
    }
    const newSourceInput = mappingSourceToInput(newSource);
    onSubmit(newSource, newSourceInput);
  }, [onSubmit, selectedSource, fieldName, jsonPointer, expression]);

  const state = isValid
    ? ZenDialogState.Invalid
    : isSubmitting
    ? ZenDialogState.Submitting
    : ZenDialogState.Displaying;

  return (
    <ZenDialog
      icon={InboxArrowDownIcon}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      title={"Select Source"}
      submit={submit ?? "Update"}
      show={show}
      state={state}
    >
      <div>
        <div className="my-2">
          <div>
            <label>
              <input
                type="radio"
                checked={selectedSource == "RMFieldMappingSource"}
                onChange={() => setSelectedSource("RMFieldMappingSource")}
              />{" "}
              Field
            </label>
          </div>
          <div className="pl-5">
            <div>
              <input
                type="text"
                value={fieldName}
                onChange={(e) => setFieldName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="my-2">
          <div>
            <label>
              <input
                type="radio"
                checked={selectedSource == "RMJsonPointerMappingSource"}
                onChange={() => setSelectedSource("RMJsonPointerMappingSource")}
              />{" "}
              JSON Pointer
            </label>
          </div>
          <div className="pl-5">
            <div>
              <input
                type="text"
                value={jsonPointer}
                onChange={(e) => setJsonPointer(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="my-2">
          <div>
            <label>
              <input
                type="radio"
                checked={selectedSource == "RMRuleMappingSource"}
                onChange={() => setSelectedSource("RMRuleMappingSource")}
              />{" "}
              Rule
            </label>
          </div>
          <div className="pl-5">
            <div>
              <RuleExpressionEditor
                expression={expression}
                disabled={false}
                onChange={setExpression}
              />
            </div>
          </div>
        </div>
      </div>
    </ZenDialog>
  );
};
