import React from "react";
import Panel from "../../common/components/panel";
import {
  AgentProductionSidesFragment,
  useAgentProductionSidesForAccountQuery,
  useAgentProductionSidesForAgentQuery,
} from "../../graphql/generated";
import { Link } from "react-router-dom";
import LoadingIcon from "../../common/components/loadingicon";

interface AgentProductionSidesForAccount {
  accountId?: string;
  agentId?: never;
}

interface AgentProductionSidesForAgent {
  agentId?: string;
  accountId?: never;
}

type AgentProductionSidesProps =
  | AgentProductionSidesForAccount
  | AgentProductionSidesForAgent;

const AgentProductionSides: React.FC<AgentProductionSidesProps> = ({
  accountId,
  agentId,
}) => {
  const [{ data: accountData, fetching: accountFetching }] =
    useAgentProductionSidesForAccountQuery({
      variables: { accountId: accountId ?? "", size: 100 },
      pause: !accountId,
    });
  const [{ data: agentData, fetching: agentFetching }] =
    useAgentProductionSidesForAgentQuery({
      variables: { agentId: agentId ?? "", size: 100 },
      pause: !agentId,
    });

  let productionSides: AgentProductionSidesFragment;
  const fetching = accountFetching || agentFetching;

  if (accountData) {
    productionSides = accountData.agentProductionSidesForAccount;
  } else if (agentData) {
    productionSides = agentData.agentProductionSidesForAgent;
  } else if (fetching) {
    return (
      <Panel>
        <Panel.Title>Production sides</Panel.Title>
        <Panel.Body>
          <LoadingIcon />
        </Panel.Body>
      </Panel>
    );
  } else {
    return <></>;
  }

  return (
    <Panel>
      <Panel.Title>
        Production sides{" "}
        <span className="text-xs text-gray-500">
          {productionSides.listings.length}/{productionSides.count}
        </span>
      </Panel.Title>
      <Panel.Body>
        <table className="table-auto w-full text-sm">
          <thead>
            <tr>
              <th className="px-2 text-left">ID</th>
              <th className="px-2 text-right">Price</th>
              <th className="px-2 text-left">Close date</th>
              <th className="px-2 text-left">Address</th>
              <th className="px-2 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {productionSides.listings.map((listing) => (
              <tr>
                <td className="px-2">
                  <Link
                    to={`/listings/${listing.id}`}
                    className="text-blue-900 hover:underline"
                  >
                    {listing.mls.shortName} {listing.mlsId}
                  </Link>
                </td>
                <td className="text-right px-2">
                  {listing.price &&
                    listing.price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="px-2">{listing.closeDate}</td>
                <td className="px-2">
                  {listing.address.address1 ?? ""} {listing.address.city ?? ""}{" "}
                  {listing.address.state ?? ""} {listing.address.zipCode ?? ""}
                </td>
                <td className="px-2">{listing.cardStatusShort}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
};

export default AgentProductionSides;
