import React from "react";
import {
  InputMaybe,
  RmFieldLookupValueFragment,
} from "../../../graphql/generated";

export type LookupValueSelectorSuggestion = {
  value: string;
  definition: string;
  coefficient: number;
};

export type IgnoreSelectedLookupValue = {
  state: "ignore";
  newValue: string;
};

export type ExistingSelectedLookupValue = {
  state: "existing";
  selectedValue: string;
  newValue: string;
};

export type NewSelectedLookupValue = {
  state: "new";
  newValue: string;
};

export type SelectedLookupValue =
  | IgnoreSelectedLookupValue
  | ExistingSelectedLookupValue
  | NewSelectedLookupValue;

export function selectedValueSpread(selected: SelectedLookupValue): {
  destination: InputMaybe<string> | null;
  create: InputMaybe<boolean> | null;
} {
  switch (selected.state) {
    case "ignore":
      return { destination: null, create: true };
    case "existing":
      return { destination: selected.selectedValue, create: false };
    case "new":
      return { destination: selected.newValue, create: true };
  }
}

export const LookupValueSelector: React.FC<{
  selected: SelectedLookupValue;
  options: RmFieldLookupValueFragment[];
  suggestions?: LookupValueSelectorSuggestion[];
  creationIsAllowed?: boolean;
  disabled?: boolean;
  setSelected: (newValue: SelectedLookupValue) => void;
}> = ({
  selected,
  options,
  suggestions,
  disabled,
  creationIsAllowed,
  setSelected,
}) => {
  const creationIsAllowedInner = creationIsAllowed ?? false;

  const innerValue =
    selected.state === "new"
      ? "special:create"
      : selected.state === "ignore"
      ? "special:null"
      : selected.selectedValue;

  const onChange = React.useCallback(
    (newValue: string) => {
      if (newValue === "special:null") {
        setSelected({ state: "ignore", newValue: selected.newValue });
      } else if (newValue === "special:create") {
        setSelected({ state: "new", newValue: selected.newValue });
      } else {
        setSelected({
          state: "existing",
          selectedValue: newValue,
          newValue: selected.newValue,
        });
      }
    },
    [selected, setSelected]
  );

  const onInputChange = React.useCallback(
    (newValue: string) => {
      setSelected({ state: "new", newValue: newValue });
    },
    [selected, setSelected]
  );

  const showCreateTextbox = selected.state === "new";

  return (
    <>
      <select
        value={innerValue}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        {suggestions &&
          suggestions.map((suggestion) => {
            return (
              <option
                value={suggestion.value}
                key={`special:suggestion-${suggestion.value}`}
                title={suggestion.definition}
              >
                {suggestion.value} (
                {suggestion.coefficient.toLocaleString(undefined, {
                  style: "percent",
                  maximumFractionDigits: 1,
                })}
                )
              </option>
            );
          })}
        {suggestions && (
          <option value="" disabled key="suggestions-separator">
            —
          </option>
        )}
        <option value="special:null" key="special:null">
          ignore
        </option>
        {creationIsAllowedInner && (
          <option value="special:create" key="special:create">
            create new
          </option>
        )}
        <option value="" disabled key="separator">
          —
        </option>
        {options.map((option) => {
          return (
            <option
              value={option.value}
              key={option.value}
              title={option.definition}
            >
              {option.value}
            </option>
          );
        })}
      </select>
      {showCreateTextbox && (
        <input
          type="text"
          value={selected.newValue}
          disabled={disabled}
          onChange={(e) => onInputChange(e.target.value)}
        />
      )}
    </>
  );
};
