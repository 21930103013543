import React from "react";
import { RmSchemaVersionCountFragment } from "../../graphql/generated";
import { classNames } from "../../common/utils/classnames";

const SchemaVersionCountsTable: React.FC<{
  counts?: RmSchemaVersionCountFragment[];
  targetSchemaVersion?: string;
}> = ({ counts, targetSchemaVersion }) => {
  if (!counts) {
    return <></>;
  }

  return (
    <table className="border-collapse-collapse">
      <thead>
        <tr>
          <th className="px-4 text-sm text-left">Schema version</th>
          <th className="px-4 text-sm text-right">Resources</th>
        </tr>
      </thead>
      <tbody>
        {counts.map((val) => {
          const shouldHighlightAfter =
            targetSchemaVersion && targetSchemaVersion === val.schemaVersion;
          const isTotal = val.schemaVersion === "total";
          return (
            <tr key={val.id}>
              <td
                className={classNames(
                  "px-4 border-t border-gray-200 text-sm text-left",
                  shouldHighlightAfter ? "border-b border-b-gray-500" : "",
                  isTotal
                    ? "border-t border-t-2 border-t-gray-200 font-semibold"
                    : ""
                )}
              >
                {val.schemaVersion}
              </td>
              <td
                className={classNames(
                  "px-4 border-t border-gray-200 text-sm text-right tabular-nums",
                  shouldHighlightAfter ? "border-b border-b-gray-500" : "",
                  isTotal
                    ? "border-t border-t-2 border-t-gray-200 font-semibold"
                    : ""
                )}
              >
                {val.count.toLocaleString(undefined)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SchemaVersionCountsTable;
